<template>
  <v-main class="overflow-y-auto">
    <nav id="header">
      <div class="left">
        <router-link class="item" to="/">
          <img src="/assets/img/header-logo.png" alt="Beechtree"/>
        </router-link>
      </div>
      <div class="right sh">
        <router-link class="item tertiary" to="/">Home</router-link>
        <router-link class="item tertiary" to="/about">About</router-link>
        <router-link class="item" to="/locations">Properties</router-link>
        <router-link class="item secondary" to="/#invest">Invest</router-link>
        <router-link class="item secondary" to="/#contact">Contact</router-link>
        <router-link class="item" to="/login">
          <img src="/assets/img/person.png" alt="Login"/>
        </router-link>
      </div>
    </nav>
    <div id="general">
      <router-view />
    </div>
  </v-main>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  components: {
  },
});
</script>
<style lang="sass">
@import '@/assets/styles/styles.sass'
@import '@/assets/styles/custom_theme.sass'
</style>
<style lang="sass" scoped>

nav#header
  padding: 0 4vh 0 4vh
  position: fixed
  top: 0
  z-index: 99
  width: 100%
  display: inline-flex
  justify-content: space-between
  background-color: #FFFFFF
  .left
    align-self: center
    img
      max-width: 33vw
  .right
    color: #5E5E5E
    display: flex
    align-items: center
    > *
      padding: 3vmin
  a
    text-decoration: none
    color: #728588
    opacity: 0.7
    &:hover
      opacity: 1
</style>