<template>
  <canvas ref="appreciation" height="100%"></canvas>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import { DateTime, Info } from 'luxon'
import axios from 'axios'

const labels = Info.monthsFormat('long');

export default {
  props: {
    locationId: Number
  },
  data: () => ({
    groupedData: null,
    ctx: null,
    chart: null
  }),
  watch: {
    locationId: function (state) {
      if (state) {
        this.get()
      }
    },
    groupedData: function (appreciations) {
      const datasets = Object.entries(appreciations).map(([key, value]) => ({ label: key, data: value  }))
      this.draw(datasets)
    }
  },
  methods: {
    get() {
      axios.get(`${process.env.VUE_APP_API}/locations/${this.locationId}/appreciations`)
        .then(
          response => {
            this.groupedData = response.data.reduce((acc, appreciation) => {
              const date = DateTime.fromISO(appreciation.date)
              if (!Object.prototype.hasOwnProperty.call(acc, date.year))              acc[date.year] = {}
              if (!Object.prototype.hasOwnProperty.call(acc[date.year], date.monthLong))  acc[date.year][date.monthLong] = 0
              acc[date.year][date.monthLong] = appreciation.value
              return acc
            }, {})
          }
        )
    },
    draw(datasets) {
      if (this.chart) this.chart.destroy()
      this.chart = new Chart(this.ctx, {
        type: 'line',
        data: { labels, datasets},
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              suggestedMax: 4000000
            }
          }
        }
      });
    }
  },
  created() {
    Chart.register(...registerables);
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$refs, 'appreciation')) {
      this.ctx = this.$refs.appreciation.getContext('2d');
    }
    if (this.locationId) {
      this.get()
    }
  }
}
</script>