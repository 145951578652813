<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="1200px"
  >
    <v-card>
      <v-card-title class="pa-4">
        <span>LOCATION</span>
      </v-card-title>
      <v-card-text>
        <v-form
          id="data"
          ref="form"
          @submit.prevent="save(data)"
        >
          <v-row>
            <v-col class="py-0" cols="12" md="8" sm="8">
              <v-text-field
                name="title"
                label="Title"
                v-model="data.title"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="6" md="2" sm="2">
              <v-text-field
                name="location_lat"
                label="Latitude"
                v-model="data.location_lat"
                type="number"
                step="any"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="6" md="2" sm="2">
              <v-text-field
                name="location_lng"
                label="Longitude"
                v-model="data.location_lng"
                type="number"
                step="any"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" md="4" sm="6">
              <v-text-field
                name="country"
                label="Country"
                v-model="data.country"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" md="4" sm="6">
              <v-text-field
                name="state"
                label="State"
                v-model="data.state"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" md="4" sm="6">
              <v-text-field
                name="city"
                label="City"
                v-model="data.city"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" md="4" sm="6">
              <v-text-field
                name="neighborhood"
                label="Neighborhood"
                v-model="data.neighborhood"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" md="2" sm="3">
              <v-text-field
                name="postalcode"
                label="Postal Code"
                v-model="data.postalcode"
                persistent-placeholder
                type="number"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" md="6" sm="9">
              <v-text-field
                name="address"
                label="Address"
                v-model="data.address"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-textarea
                name="description"
                label="Description"
                v-model="data.description"
                persistent-placeholder
                rows="2"
                outlined
                required
              ></v-textarea>
            </v-col>
            <v-col class="py-0" cols="4">
              <v-text-field
                name="property_size"
                label="Property Size"
                v-model="data.property_size"
                placeholder="NN / NN"
                suffix="m²"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="4">
              <v-text-field
                name="year_built"
                label="Year Built"
                v-model="data.year_built"
                placeholder="AAAA-MM-DD"
                type="number"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="4">
              <v-text-field
                name="mortgage"
                label="Mortgage"
                v-model="data.mortgage"
                prefix="$"
                type="number"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="3">
              <v-text-field
                name="number_of_buildings"
                label="N° of Buildings"
                v-model="data.number_of_buildings"
                type="number"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="3">
              <v-text-field
                name="total_rooms"
                label="Total Rooms"
                v-model="data.total_rooms"
                type="number"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="3">
              <v-text-field
                name="available_rooms"
                label="Available Rooms"
                v-model="data.available_rooms"
                type="number"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="3">
              <v-text-field
                name="rent_cost"
                label="Rent Cost"
                v-model="data.rent_cost"
                type="number"
                prefix="$"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-combobox
                name="rooms"
                label="Rooms"
                v-model="data.rooms"
                chips multiple solo
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          type="submit"
          form="data"
        >
          Save
        </v-btn>
        <v-btn
          v-if="id"
          text
          @click="del()"
        >
          Delete
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="this.$emit('close', null)"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { location } from '@/config/preset'
export default {
  props: {
    open: Boolean,
    id: Number
  },
  data: () => ({
    data: structuredClone(location),
  }),
  watch: {
    open: function (state) {
      if (state && this.id) {
        this.get();
      }
      if (state === false) {
        this.data = structuredClone(location)
      }
    }
  },
  computed: {
    show () {
      return this.open
    }
  },
  methods: {
    get () {
      axios.get(`${process.env.VUE_APP_API}/locations/${this.id}`)
        .then(
          response => {
            this.data = response.data;
          }
        )
    },
    del () {
      axios.delete(`${process.env.VUE_APP_API}/locations/${this.id}`)
        .then(
          () => {
            this.$emit('close', false)
          }
        )
    },
    save (data) {
      if ('id' in data) {
        axios.put(`${process.env.VUE_APP_API}/locations/${data.id}`, data)
          .then(
            response => {
              this.$emit('close', response.data)
            }
          )
      } else {
        axios.post(`${process.env.VUE_APP_API}/locations`, data)
          .then(
            response => {
              this.$emit('close', response.data)
            }
          )
      }
    }
  },
  mounted() {
    if (this.open && this.id) {
      this.get();
    }
  }
}
</script>