export const location = {
    title: undefined,
    location_lat: undefined,
    location_lng: undefined,
    country: undefined,
    state: undefined,
    city: undefined,
    neighborhood: undefined,
    postalcode: undefined,
    address: undefined,
    description: undefined,
    property_size: undefined,
    year_built: undefined,
    mortgage: undefined,
    number_of_buildings: undefined,
    total_rooms: undefined,
    available_rooms: undefined,
    rent_cost: undefined,
    images: []
}

export const user = {
    email: undefined,
    alias: undefined,
    phone: undefined,
    role: undefined
}

export const investment = {
    userId: undefined,
    locationId: undefined,
    equity: undefined,
    date: undefined
}

export const rental = {
    locationId: undefined,
    date: undefined,
    room: undefined,
    amount: undefined,
    company: undefined
}

export const appreciation = {
    locationId: undefined,
    date: undefined,
    value: undefined
}

export const register = {
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    phone: undefined,
    investor: undefined,
    reference: undefined
}