<template>
  <canvas ref="equity" height="100%"></canvas>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import axios from 'axios'
export default {
  props: {
    locationId: Number
  },
  data: () => ({
    groupedData: null,
    ctx: null,
    chart: null,
    investors: []
  }),
  watch: {
    locationId: function (state) {
      if (state) {
        this.get()
      }
    },
    groupedData: function (equitys) {
      const data = Object.values(equitys)
      const labels = Object.keys(equitys).map(
        (userId) => {
          const investor = this.investors.find((investor) => (investor.id == userId))
          return investor ? investor.alias : 'User not found'
        }
      )
      this.draw(labels, data)
    }
  },
  methods: {
    get() {
      axios.get(`${process.env.VUE_APP_API}/locations/${this.locationId}/users`)
        .then(
          response => {
            this.investors = response.data
            axios.get(`${process.env.VUE_APP_API}/locations/${this.locationId}/investments`)
              .then(
                response => {
                  this.groupedData = response.data.reduce((acc, equity) => {
                    if (!Object.prototype.hasOwnProperty.call(acc, equity.userId)) acc[equity.userId] = 0
                    acc[equity.userId] += equity.amount
                    return acc
                  }, {})
                }
              )
          }
        )
    },
    draw(labels, data) {
      if (this.chart) this.chart.destroy()
      this.chart = new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            data: data,
            hoverOffset: 4
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            }
          }
        }
      });
    }
  },
  created() {
    Chart.register(...registerables);
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$refs, 'equity')) {
      this.ctx = this.$refs.equity.getContext('2d');
    }
    if (this.locationId) {
      this.get()
    }
  }
}
</script>