<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="1200px"
  >
    <v-card v-if="locations && users">
      <v-card-title class="pa-4">
        <span>INVESTMENTS</span>
      </v-card-title>
      <v-card-text>
        <v-form
          id="data"
          ref="form"
          @submit.prevent="save(data)"
        >
          <v-row>
            <v-col class="py-0" cols="12" md="6" sm="6">
              <v-select
                v-model="data.locationId"
                :items="locations"
                item-title="title"
                item-value="id"
                label="Building"
                persistent-placeholder
                required
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6" sm="6">
              <v-select
                v-model="data.userId"
                :items="users"
                item-title="email"
                item-value="id"
                label="User"
                persistent-placeholder
                required
              />
            </v-col>
            <v-col class="py-0" cols="6" md="6" sm="12">
              <v-text-field
                name="Amount"
                label="Amount"
                v-model="data.amount"
                type="number"
                persistent-placeholder
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="6" md="6" sm="12">
              <v-text-field
                name="date"
                label="Date"
                v-model="data.date"
                type="date"
                persistent-placeholder
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          type="submit"
          form="data"
        >
          Save
        </v-btn>
        <v-btn
          v-if="id"
          text
          @click="del()"
        >
          Delete
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="this.$emit('close', null)"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { investment } from '@/config/preset'
export default {
  props: {
    open: Boolean,
    id: Number,
    userId: Number,
    locationId: Number
  },
  data: () => ({
    data: structuredClone(investment),
    locations: null,
    users: null,
  }),
  watch: {
    open: function (state) {
      if (state && this.id) {
        this.get();
      }
      if (state == false) {
        this.data = structuredClone(investment)
      }
    },
    userId: function (state) {
      if (state) {
        this.data.userId = state;
      }
    },
    locationId: function (state) {
      if (state) {
        this.data.locationId = state;
      }
    }
  },
  computed: {
    show () {
      return this.open
    }
  },
  methods: {
    get () {
      axios.get(`${process.env.VUE_APP_API}/investments/${this.id}`)
        .then(
          response => {
            this.data = response.data;
          }
        )
    },
    del () {
      axios.delete(`${process.env.VUE_APP_API}/investments/${this.id}`)
        .then(
          () => {
            this.$emit('close', false)
          }
        )
    },
    save (data) {
      if ('id' in data) {
        axios.put(`${process.env.VUE_APP_API}/investments/${data.id}`, data)
          .then(
            response => {
              this.$emit('close', response.data)
            }
          )
      } else {
        axios.post(`${process.env.VUE_APP_API}/investments`, data)
          .then(
            response => {
              this.$emit('close', response.data)
            }
          )
      }
    },
    getUsers () {
      axios.get(`${process.env.VUE_APP_API}/users`)
        .then(
          response => {
            this.users = response.data;
            if (this.userId) {
              this.data.userId = this.userId;
            }
          }
        )
    },
    getLocations () {
      axios.get(`${process.env.VUE_APP_API}/locations`)
        .then(
          response => {
            this.locations = response.data;
            if (this.locationId) {
              this.data.locationId = this.locationId;
            }
          }
        )
    }
  },
  mounted () {
    this.getUsers()
    this.getLocations()
    if (this.id) {
      this.get();
    }
  }
}
</script>