<template>
  <v-toolbar color="white" class="elevation-1">
    <v-btn @click="$router.back()" icon>
      <v-icon color="grey">mdi-arrow-left</v-icon>
    </v-btn>
    <v-toolbar-title>{{info.title}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn @click="dialogOpen.info=true" color="secondary" variant="flat">
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn v-if="this.id" @click="dialogOpen.images=true" color="secondary" variant="flat" class="ml-2">
      <v-icon>mdi-image-plus</v-icon>
    </v-btn>
  </v-toolbar>
  <section>
    <!------------------------------------------------------------- RENTAL -->
    <article>
      <v-card class="my-2 mx-2">
        <v-toolbar color="primary">
          <v-toolbar-title>Rental</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialogOpen.rental=true" icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-table height="calc(100vh - 64px - 64px - 2rem)" fixed-header>
          <thead>
            <tr>
              <th scope="ID" class="text-left">
                ID
              </th>
              <th scope="Date" class="text-left">
                Date
              </th>
              <th scope="Room" class="text-left">
                Room
              </th>
              <th scope="Amount" class="text-left">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="rental in rentals" :key="rental.id">
              <tr @click="openRentalDialog(rental.id)">
                <td class="compact">{{ rental.id }}</td>
                <td>{{ rental.date }}</td>
                <td>{{ rental.room }}</td>
                <td>{{ rental.amount }}</td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </v-card>
    </article>
    <!------------------------------------------------------------- USERS -->
    <article>
      <v-card class="my-2 mx-2">
        <v-toolbar color="primary">
          <v-toolbar-title>Appreciation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialogOpen.appreciation=true" icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-table height="calc(100vh - 64px - 64px - 2rem)" fixed-header>
          <thead>
            <tr>
              <th scope="ID">
                ID
              </th>
              <th scope="Date">
                Date
              </th>
              <th scope="Value">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="appreciation in appreciations" :key="appreciation.id">
              <tr  @click="openAppreciationDialog(appreciation.id)">
                <td class="compact">{{ appreciation.id }}</td>
                <td>{{ appreciation.date }}</td>
                <td>{{ appreciation.value }}</td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </v-card>
    </article>
  </section>
  <manage-location-dialog
    v-if="id"
    :open="dialogOpen.info"
    :id="id"
    @close="closeInfoDialog($event)"
  ></manage-location-dialog>
  <manage-location-images-dialog
    v-if="id"
    :open="dialogOpen.images"
    :id="id"
    @close="closeImagesDialog($event)"
  ></manage-location-images-dialog>
  <manage-rental-dialog
    v-if="dialogOpen.rental"
    :open="dialogOpen.rental"
    :id="rentalId"
    :locationId="id"
    @close="closeRentalDialog($event)"
  ></manage-rental-dialog>
  <manage-appreciation-dialog
    v-if="dialogOpen.appreciation"
    :open="dialogOpen.appreciation"
    :id="appreciationId"
    :locationId="id"
    @close="closeAppreciationDialog($event)"
  ></manage-appreciation-dialog>
</template>

<script>
import axios from 'axios';
import ManageLocationDialog from '@/components/dialogs/ManageLocationDialog.vue';
import ManageLocationImagesDialog from '@/components/dialogs/ManageLocationImagesDialog.vue';
import ManageRentalDialog from '@/components/dialogs/ManageRentalDialog.vue';
import ManageAppreciationDialog from '@/components/dialogs/ManageAppreciationDialog.vue';
export default {
  components: {
    ManageLocationDialog,
    ManageLocationImagesDialog,
    ManageRentalDialog,
    ManageAppreciationDialog
  },
  data: () => ({
    id: null,
    rentalId: null,
    appreciationId: null,
    dialogOpen: {
      info: false,
      images: false,
      rental: false,
      appreciation: false
    },
    info: [],
    rentals: [],
    appreciations: []
  }),
  methods: {
    getInfo () {
      axios.get(`${process.env.VUE_APP_API}/locations/${this.id}`)
      .then(
        response => {
            this.info = response.data;
          }
        )
    },
    getRentals () {
      axios.get(`${process.env.VUE_APP_API}/locations/${this.id}/rentals`)
      .then(
        response => {
            this.rentals = response.data;
          }
        )
    },
    getAppreciations () {
      axios.get(`${process.env.VUE_APP_API}/locations/${this.id}/appreciations`)
      .then(
        response => {
            this.appreciations = response.data;
          }
        )
    },
    openRentalDialog(id) {
      this.dialogOpen.rental = true;
      if (id) { this.rentalId = id }
    },
    openAppreciationDialog(id) {
      this.dialogOpen.appreciation = true;
      if (id) { this.appreciationId = id }
    },
    closeInfoDialog(changes) {
      this.dialogOpen.info = false;
      if (changes === false) this.$router.push('/account/manage')
      else if (changes !== null) this.getInfo();
    },
    closeImagesDialog(changes) {
      this.dialogOpen.images = false;
      if (changes === false) this.$router.push('/account/manage')
      else if (changes !== null) this.getInfo();
    },
    closeRentalDialog(changes) {
      this.dialogOpen.rental = false;
      if (changes !== null) this.getRentals();
      this.rentalId = null
    },
    closeAppreciationDialog(changes) {
      this.dialogOpen.appreciation = false;
      if (changes !== null) this.getAppreciations();
      this.appreciationId = null
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
      this.getInfo()
      this.getRentals()
      this.getAppreciations()
    } else {
      this.$router.back()
    }
  }
}
</script>
<style scoped>
section {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

article {
  flex-basis: 400px;
  min-width: 50%;
}

.compact {
  width: 40px;
}
</style>