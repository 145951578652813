<template>
  <v-toolbar color="white" class="elevation-1">
    <v-btn @click="$router.back()" icon>
      <v-icon color="grey">mdi-arrow-left</v-icon>
    </v-btn>
    <v-toolbar-title>{{info.email}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn @click="openDialog()" color="secondary" variant="flat">
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
  </v-toolbar>
  <section>
    <article>
      <manage-user-investments :id="id" />
    </article>
  </section>
  <manage-user-dialog
    v-if="id"
    :open="dialogOpen"
    :id="id"
    @close="closeDialog($event)"
  />
</template>

<script>
import axios from 'axios';
import ManageUserDialog from '@/components/dialogs/ManageUserDialog.vue';
import ManageUserInvestments from '@/components/ManageUserInvestments.vue';
export default {
  components: {
    ManageUserDialog,
    ManageUserInvestments
},
  data: () => ({
    id: null,
    dialogOpen: false,
    info: [],
  }),
  methods: {
    get () {
      axios.get(`${process.env.VUE_APP_API}/users/${this.id}`)
      .then(
        response => {
            this.info = response.data;
          }
        )
    },
    openDialog() {
      this.dialogOpen = true;
    },
    closeDialog(changes) {
      this.dialogOpen = false;
      this.id = null;
      if (changes == false) {
        this.$router.push('/account/manage')
      } else if (changes !== null) {
        this.get();
      }
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
      this.get()
    } else {
      this.$router.back()
    }
  }
}
</script>