<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="1200px"
  >
    <v-card>
      <v-card-title class="pa-4">
        <span>USER</span>
      </v-card-title>
      <v-card-text>
        <v-form
          id="data"
          ref="form"
          @submit.prevent="save(data)"
          lazy-validation
        >
          <v-row>
            <v-col class="py-0" cols="12" md="6" sm="6">
              <v-text-field
                name="email"
                label="Email"
                v-model="data.email"
                type="email"
                persistent-placeholder
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" md="6" sm="6">
              <v-text-field
                name="alias"
                label="Alias"
                v-model="data.alias"
                persistent-placeholder
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="6" md="6" sm="12">
              <v-text-field
                name="phone"
                label="Phone"
                v-model="data.phone"
                persistent-placeholder
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="6" md="6" sm="12">
              <v-select
                v-model="data.role"
                :items="roles"
                item-title="title"
                item-value="value"
                label="Role"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          type="submit"
          form="data"
        >
          Save
        </v-btn>
        <v-btn
          v-if="id"
          text
          @click="del()"
        >
          Delete
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="this.$emit('close', null)"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { user } from '@/config/preset'
export default {
  props: {
    open: Boolean,
    id: Number
  },
  data: () => ({
    data: structuredClone(user),
    roles: [
      { title: 'Admin', value: 'admin' },
      { title: 'User', value: 'user' }
    ]
  }),
  watch: {
    open: function (state) {
      if (state && this.id) {
        this.get();
      }
      if (state == false) {
        this.data = structuredClone(user)
      }
    }
  },
  computed: {
    show () {
      return this.open
    }
  },
  methods: {
    get () {
      axios.get(`${process.env.VUE_APP_API}/users/${this.id}`)
        .then(
          response => {
            this.data = response.data;
          }
        )
    },
    del () {
      axios.delete(`${process.env.VUE_APP_API}/users/${this.id}`)
        .then(
          () => {
            this.$emit('close', false)
          }
        )
    },
    save(data) {
      this.$refs.form.validate().then(a => {
        console.log(a);
      })
      if ('id' in data) {
        axios.put(`${process.env.VUE_APP_API}/users/${data.id}`, data)
          .then(
            response => {
              this.$emit('close', response.data)
            }
          )
      } else {
        axios.post(`${process.env.VUE_APP_API}/users`, data)
          .then(
            response => {
              this.$emit('close', response.data)
            }
          )
      }
    }
  }
}
</script>