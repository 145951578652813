<template>
  <v-row justify="center">
    <v-dialog v-model="show">
      <v-card
        class="mx-auto"
        width="400"
        height="500"
        max-width="100%"
        scrollable
      >
        <v-carousel
          v-if="images.length"
          :continuous="false"
          :cycle="true"
          :show-arrows="true"
          hide-delimiters
          delimiter-icon="mdi-minus"
          height="250px"
          width="100%"
        >
          <v-carousel-item
            v-for="(image, i) in images"
            :key="i"
          >
            <img :src="image" :alt="building.title">
          </v-carousel-item>
        </v-carousel>
        <!---------------------------------------------------------------------------------- BUILDING DATA -->
        <v-card-title>
            {{building.address}}. {{building.neighborhood}}.
        </v-card-title>
        <v-card-subtitle>
            <span>{{building.title}}</span> - {{building.city}} {{building.state}}. CP: {{building.postalcode}}.
        </v-card-subtitle>
        <v-card-text>
          {{building.description}}
        </v-card-text>
        <v-card-actions>
          <v-btn
            variant="text"
            color="primary"
            @click="reveal = true"
          >
            VIEW DETAILS
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="emitDialogState(false)"
          >
            CLOSE
          </v-btn>
        </v-card-actions>
        <!----------------------------------------------- Back Card -->
        <v-expand-transition>
          <v-card
            v-if="reveal"
            class="transition-fast-in-fast-out v-card--reveal"
            style="height: 100%;"
          >
            <v-card-title>
              {{building.title}}.
            </v-card-title>
            <v-card-subtitle>
              {{building.address}}. {{building.neighborhood}}.
            </v-card-subtitle>
            <v-card-text class="pb-0">
              <v-list density="compact">
                <v-list-subheader>DETAILS</v-list-subheader>
                <v-list-item class="ml-4">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-stretch-to-page-outline"></v-icon>
                  </template>
                  <v-list-item-title>
                    <small>Property size:
                      <strong>&nbsp;&nbsp;{{building.property_size}}</strong>
                    </small>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="ml-4">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-home-group"></v-icon>
                  </template>
                  <v-list-item-title>
                    <small>Number of buildings:
                      <strong>&nbsp;&nbsp;{{building.number_of_buildings}} building</strong>
                    </small>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="ml-4">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-calendar-range"></v-icon>
                  </template>
                  <v-list-item-title>
                    <small>Year built:
                      <strong>&nbsp;&nbsp;{{building.year_built}}</strong>
                    </small>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-btn
                variant="text"
                color="primary"
                @click="reveal = false"
              >
                GO BACK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expand-transition>
        <!---------------------------------------------------------------------------------- / BUILDING DATA -->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    open: Boolean,
    building: Object
  },
  data: () => ({
    dialog: true,
    reveal: false
  }),
  computed: {
    show() {
      return this.open
    },
    images() {
      if (!this.building.images) return []
      if (!this.building.images.length) return []
      return this.building.images;
    }
  },
  methods: {
    emitDialogState (event) {
      this.$emit('close', event)
    },
  }
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
.v-dialog--custom {
  width: 400px !important;
  max-width: 80%;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>