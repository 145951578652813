<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style>
  @import '@/assets/styles/fonts.css';

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

	html, body {
		margin: 0;
		padding: 0;
  }

</style>