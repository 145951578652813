<template>
  <form
    id="data"
    ref="form"
    action="https://formspree.io/f/mnqyveok"
    method="POST"
    @validation="validation = $event"
  >
    <v-row>
      <v-col class="py-6" cols="6" xl="6" lg="6" md="12" sm="12">
        <h2>Name*</h2>
        <v-text-field
          name="firstname"
          v-model="data.firstname"
          hint="First Name"
          persistent-hint
          variant="underlined"
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-6" cols="6" xl="6" lg="6" md="12" sm="12">
        <h2>&nbsp;</h2>
        <v-text-field
          name="lastname"
          v-model="data.lastname"
          hint="Last Name"
          persistent-hint
          variant="underlined"
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-6" cols="12">
        <h2>Email*</h2>
        <v-text-field
          name="email"
          v-model="data.email"
          type="email"
          variant="underlined"
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-6" cols="12">
        <h2>Phone*</h2>
        <v-text-field
          name="phone"
          v-model="data.phone"
          variant="underlined"
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-6" cols="12">
        <h2>Are you an accredited investor?*</h2>
        <v-select
          name="investor"
          v-model="data.investor"
          :items="investorOptions"
          variant="underlined"
          required
        ></v-select>
      </v-col>
      <v-col class="py-6" cols="12">
        <h2>How did you find out about us?*</h2>
        <v-select
          name="reference"
          v-model="data.reference"
          :items="referenceOptions"
          variant="underlined"
          required
        ></v-select>
      </v-col>
      <v-col class="py-6 text-center" cols="12">
        <button
          class="button button-secondary"
          text
          type="submit"
          form="data"
          block
        >
          SUBMIT FORM
        </button>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { register } from '@/config/preset'
export default {
  props: {
    locationId: Number
  },
  data: () => ({
    data: structuredClone(register),
    investorOptions: ["Yes", "No", "Not Sure"],
    referenceOptions: ["Reference", "LinkedIn", "Advertising", "Email", "Podcast"],
    validation: {}
  })
}
</script>
<style lang="sass" scoped>
  .v-row
    margin-top: 12vh
  h2
    font-family: Raleway
    font-weight: 400
</style>