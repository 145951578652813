import { parseJwt } from '@/scripts/misc'
export default {
  namespaced: true,
  state: {
    profile: null
  },
  getters: {
    getProfile: state => state.profile
  },
  mutations: {
    setProfile (state, data) {
      state.profile = data
   }
  },
  actions: {
    // --- Desempaca del Storage
    unpack ({commit, state}) {
      const profile = parseJwt(sessionStorage.getItem('gsi'));
      commit('setProfile', profile)
      return state.profile
    },
    // --- Inicializa Google Accounts
    initialize({ dispatch }) {
      // eslint-disable-next-line no-undef
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GSI_KEY,
        callback: (response) => {
          sessionStorage.setItem('gsi', response.credential)
          dispatch('unpack')
        }
      })
    },
    reset({ commit }) {
      commit('setProfile', null)
    }
  }
}