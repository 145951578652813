<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="1200px"
  >
    <v-card>
      <v-card-title class="pa-4">
        <span>IMAGES</span>
      </v-card-title>
      <v-card-text>

        <v-text-field
          ref="driveUrlImageInput"
          name="image"
          label="Image Google Drive URL"
          placeholder="Paste here a google drive image with public privacy"
          v-model="urlImage"
          append-icon="mdi-plus"
          @click:append="add(urlImage)"
          persistent-placeholder
          outlined
          required
        ></v-text-field>

        <article>
          <div class="image"
            v-for="(image, i) in images"
            :key="i"
          >
            <img :src="image" alt="preview">
            <v-btn
              icon="mdi-delete"
              color="error"
              variant="plain"
              @click="del(i)"
            ></v-btn>
          </div>
        </article>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="id"
          text
          @click="save()"
        >
          Save
        </v-btn>
        <v-btn
          v-if="id"
          text
          @click="del()"
        >
          Delete
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="this.$emit('close', null)"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { location } from '@/config/preset'
const privateDriveUrl = 'https://drive.google.com/file/d/';
const publicDriveUrl = 'https://drive.google.com/uc?id=';
export default {
  props: {
    open: Boolean,
    id: Number
  },
  data: () => ({
    urlImage: '',
    images: []
  }),
  watch: {
    open: function (state) {
      if (state && this.id) {
        this.get();
      }
      if (state === false) {
        this.data = structuredClone(location)
      }
    }
  },
  computed: {
    show () {
      return this.open
    }
  },
  methods: {
    get () {
      axios.get(`${process.env.VUE_APP_API}/locations/${this.id}`)
        .then(
          response => {
            if (response.data.images) {
              this.images = response.data.images;
            }
          }
        )
    },
    del(idx) {
      this.images.splice(idx, 1) 
    },
    add (urlImage) {
      let embedibleURL = urlImage;
      if (urlImage.substring(0, 32) == privateDriveUrl) {
        const driveImageId = urlImage.substring(32).match(/^[\w-]+/g);
        embedibleURL = publicDriveUrl + driveImageId;
      }
      this.images.push(embedibleURL);
      this.$refs.driveUrlImageInput.reset();
    },
    save () {
      if (this.id) {
        axios.patch(`${process.env.VUE_APP_API}/locations/${this.id}`, {images: this.images})
          .then(
            response => {
              this.$emit('close', response.data)
            }
          )
      }
    }
  },
  mounted() {
    if (this.open && this.id) {
      this.get();
    }
  }
}
</script>
<style lang="sass" scoped>
  article
    display: flex
    flex-wrap: wrap
    justify-content: stretch
    .image
      position: relative
      margin: 1rem
      img
        width: 300px
        height: 200px
        object-fit: cover
        box-shadow: 0 0 10px #999
        border-radius: 1rem
      .v-btn
        position: absolute
        bottom: 0.5rem
        right: 0.5rem
</style>