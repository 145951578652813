export const menuCatalog = [
    {
        icon: 'mdi-map',
        title: 'Locations',
        value: 'locations',
        path: 'locations'
    },
    {
        icon: 'mdi-database-marker-outline',
        title: 'Manage',
        value: 'manage',
        path: 'manage'
    },
    // {
    //     icon: 'mdi-cash-check',
    //     title: 'Mis inversiones',
    //     value: 'investments',
    //     path: 'investments'
    // },
    {
        icon: 'mdi-chart-bar',
        title: 'Statistics',
        value: 'statistics',
        path: 'statistics'
    }
]

export const userMenu = [
    'Locations'
]
export const adminMenu = [
    'Locations',
    'Manage',
    'Statistics'
]