<template>
  <section>
    <router-link to="/">
      <img src="/assets/img/logo.png" class="logo" alt="Bechtree Logo">
    </router-link>
    <img src="/assets/img/tree.png" class="tree" alt="Bechtree Tree">
    <h1 class="text-secondary">Log in into your account</h1>
    <!-- <p class="sh4">- We promote transparency -</p> -->
    <div ref="loginBtn" class="login-button"></div>
    <h3 class="font-weight-regular d-none d-sm-flex">
      We have at your disposal a portal where you can check your investment and asset recovery
    </h3>
  </section>
</template>

<script>
const renderButton = (button) => {
  // eslint-disable-next-line no-undef
  google.accounts.id.renderButton(
    button,
    {
      size: "large",
      theme: "outline",
      width: "366"
    }
  )
}

import { mapGetters,  mapActions} from 'vuex'
export default {
  computed: {
    ...mapGetters('gsi', { profile: 'getProfile' })
  },
  methods: {
    ...mapActions('gsi', { gsiInitialize: 'initialize' })
  },
  mounted () {
    if (window.google) {
      this.gsiInitialize()
      renderButton(this.$refs.loginBtn)
    }
    else {
      window.addEventListener('load', () => {
        this.gsiInitialize()
        renderButton(this.$refs.loginBtn)
      })
    }
  },
  watch: {
    profile (value) {
      if (value) {
        this.$router.push('/account')
      }
    }
  }
};
</script>
<style scoped>
section {
    background-color: #C2CACC;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 45px;
  }
  h1, p, h3 {
    margin: 3vh 0;
    width: 600px;
    max-width: 100%;
  }
  h1 {
    font-size: 2.6rem;
    font-weight: 500;
  }
  p {
    color: #383838;
  }
  h3 {
    color: #383838;
  }
  .logo {
    position: absolute;
    left: 3vw;
    top: 3vw;
    height: 45px;
    width: auto;
  }
  .tree {
    width: 80vw;
    height: 40vh;
    object-fit: contain;
  }
  .login-button {
    box-shadow: 0 0 10px #728588;
  }
</style>