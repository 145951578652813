<template>
  <v-autocomplete
    class="mt-4 mx-4"
    label="Location"
    :items="locations"
    item-title="title"
    item-value="id"
    v-model="selectedLocation"
    :hide-details="true"
    variant="solo"
  ></v-autocomplete>
  <section>
    <!------------------------------------------------------------- LOCATIONS -->
    <article>
      <v-card class="ma-2 px-2">
        <h4 class="text-overline font-weight-bold text-secondary">Appreciation</h4>
        <div class="chart" id="appreciation">
          <location-appreciation-statistics
            v-if="selectedLocation"
            :locationId="selectedLocation"
          />
        </div>
      </v-card>
      <v-card class="ma-2 px-2">
        <h4 class="text-overline font-weight-bold text-secondary">Rental</h4>
        <div class="chart" id="rental">
          <location-rental-statistics
             v-if="selectedLocation"
            :locationId="selectedLocation"
          />
        </div>
      </v-card>
    </article>
    <!------------------------------------------------------------- USERS -->
    <article>
      <v-card class="ma-2 px-2">
        <h4 class="text-overline font-weight-bold text-secondary">Rental</h4>
        <div class="chart" id="equity">
          <location-equity-statistics
            v-if="selectedLocation"
            :locationId="selectedLocation"
          />
        </div>
      </v-card>
    </article>
  </section>
</template>

<script>
import axios from 'axios';
import LocationAppreciationStatistics from '@/components/LocationAppreciationStatistics.vue';
import LocationRentalStatistics from '@/components/LocationRentalStatistics.vue';
import LocationEquityStatistics from '@/components/LocationEquityStatistics.vue';
export default {
  data: () => ({
    locations: [],
    selectedLocation: null
  }),
  components: {
    LocationAppreciationStatistics,
    LocationRentalStatistics,
    LocationEquityStatistics
  },
  methods: {
    getLocations () {
      axios.get(`${process.env.VUE_APP_API}/locations`)
        .then(
          response => {
            this.locations = response.data;
            this.selectedLocation = this.locations[0].id;
          }
        )
    }
  },
  mounted() {
    this.getLocations()
  }
}
</script>
<style scoped>
section {
  display: flex;
  flex-wrap: wrap;
}
article {
  flex-basis: 400px;
}
article:first-child {
  flex-grow: 1;
}
article > .chart {
  border: 1px solid;
  overflow-y: auto;
}
#appreciation,
#rental {
  height: calc((100vh - 56px - 7.5rem) * 0.5);
}
#equity {
  height: calc(100vh - 56px - 5rem) !important;
  width: calc(100vh - 64px - 5rem) !important;
}
</style>
