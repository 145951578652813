<template>
  <div class="google-map" ref="googleMap" id="googleMap"></div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader"
export default {
  props: {
    markers: Array
  },
  data: () => ({
    loader: null,
    map: null,
    options: {
      center: { lat: 32.7294694, lng: -117.0153078 },
      zoom: 11
    }
  }),
  mounted() {
    this.initializeLoader()
    this.initializeMap()
  },
  methods: {
    initializeLoader() {
      this.loader = new Loader({
        apiKey: process.env.VUE_APP_MAPS_KEY,
        libraries: ["places"]
      });
    },
    initializeMap() {
      this.loader.load()
        .then((google) => {
          this.map = new google.maps.Map(this.$refs.googleMap, this.options);
          this.infoWindow = new google.maps.InfoWindow();
          setTimeout(() => {
            this.markers.forEach(marker => this.setMarker(marker));
          }, 1000)
        })
        .catch(e => {
          console.log(e);
        });
    },
    setMarker(location) {
      const position = location.position;
      // eslint-disable-next-line no-undef
      let myLatlng = new google.maps.LatLng(position.lat, position.lng);
      // eslint-disable-next-line no-undef
      let marker = new google.maps.Marker({ position: myLatlng, title: location.title });
      marker.setMap(this.map);
      marker.addListener('click', ()=> this.$emit('selected', location.id) )
    },
    handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
      infoWindow.open(this.map);
    }
  }
}
</script>
<style scoped>
  .google-map {
    width: 100%;
    height: 100vh;
  }
</style>