<template>
  <section>
    <!------------------------------------------------------------- LOCATIONS -->
    <article>
      <v-card class="my-2 mx-2">
        <v-toolbar color="primary">
          <v-toolbar-title>Locations</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialogOpen.location=true" icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-table height="calc(100vh - 64px - 2rem)" fixed-header>
          <thead>
            <tr>
              <th scope="ID" class="text-left">
                ID
              </th>
              <th scope="Title" class="text-left">
                Title
              </th>
              <th scope="City" class="text-left">
                City
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="location in locations" :key="location.id" >
              <tr @click="$router.push('/account/manage/locations/' + location.id)">
                <td class="compact">{{ location.id }}</td>
                <td>{{ location.title }}</td>
                <td>{{ location.city }}</td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </v-card>
    </article>
    <!------------------------------------------------------------- USERS -->
    <article>
      <v-card class="my-2 mx-2">
        <v-toolbar color="primary">
          <v-toolbar-title>Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialogOpen.user=true" icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-table height="calc(100vh - 64px - 2rem)" fixed-header>
          <thead>
            <tr>
              <th scope="ID" class="text-left">
                ID
              </th>
              <th scope="Role" class="text-left">
                Role
              </th>
              <th scope="Email" class="text-left">
                Email
              </th>
              <th scope="Phone" class="text-left">
                Phone
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="user in users" :key="user.id">
              <tr @click="$router.push('/account/manage/users/' + user.id)">
                <td class="compact">{{ user.id }}</td>
                <td>{{ user.role }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.phone }}</td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </v-card>
    </article>
  </section>
  <manage-location-dialog
    :id="null"
    :open="dialogOpen.location"
    @close="closeLocationDialog($event)"
  />
  <manage-user-dialog
    :id="null"
    :open="dialogOpen.user"
    @close="closeUserDialog($event)"
  />
</template>

<script>
import axios from 'axios';
import ManageLocationDialog from '@/components/dialogs/ManageLocationDialog.vue';
import ManageUserDialog from '@/components/dialogs/ManageUserDialog.vue';
export default {
  data: () => ({
    dialogOpen: {
      location: false,
      user: false
    },
    locations: [],
    users: [],
  }),
  components: {
    ManageLocationDialog,
    ManageUserDialog
  },
  methods: {
    getLocations () {
      axios.get(`${process.env.VUE_APP_API}/locations`)
        .then(
          response => {
            this.locations = response.data;
          }
        )
    },
    getUsers () {
      axios.get(`${process.env.VUE_APP_API}/users`)
        .then(
          response => {
            this.users = response.data;
          }
        )
    },
    closeLocationDialog(changes) {
      this.dialogOpen.location = false;
      if (changes !== null) {
        this.getLocations();
      }
    },
    closeUserDialog(changes) {
      this.dialogOpen.user = false;
      if (changes !== null) {
        this.getUsers();
      }
    }
  },
  mounted() {
    this.getLocations()
    this.getUsers()
  }
}
</script>
<style scoped>
section {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  article {
    flex-basis: 400px;
    min-width: 50%;
  }
  .compact {
    width: 40px;
  }
</style>
