<template>
  <v-card class="my-2 mx-2" v-if="id">
    <v-toolbar color="primary">
      <v-toolbar-title>Investments</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="dialogOpen=true" icon>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-table height="calc(100vh - 64px - 64px - 2rem)" fixed-header v-if="investments">
      <thead>
        <tr>
          <th scope="ID" class="text-left">
            ID
          </th>
          <th scope="Building" class="text-left">
            Building
          </th>
          <th scope="Amount" class="text-left">
            Amount
          </th>
          <th scope="Date" class="text-left">
            Date
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="investment in investments" :key="investment.id">
          <tr @click="openDialog(investment.id)">
            <td class="compact">{{ investment.id }}</td>
            <td>{{ investment.location.title }}</td>
            <td>{{ investment.amount }}</td>
            <td>{{ investment.date }}</td>
          </tr>
        </template>
      </tbody>
    </v-table>
  </v-card>
  <manage-investment-dialog
    v-if="dialogOpen"
    :id="investmentId"
    :userId="id"
    :locationId="null"
    :open="dialogOpen"
    @close="closeDialog($event)"
  />
</template>

<script>
import axios from 'axios';
import ManageInvestmentDialog from '@/components/dialogs/ManageInvestmentDialog.vue';
export default {
  components: {
    ManageInvestmentDialog
  },
  props: {
    id: Number
  },
  data: () => ({
    dialogOpen: false,
    investmentId: null,
    investments: null
  }),
  watch: {
    id: function (state) {
      if (state) {
        this.get();
      }
    }
  },
  methods: {
    get() {
      axios.get(`${process.env.VUE_APP_API}/users/${this.id}/investments?_expand=location`)
        .then(
          response => {
            this.investments = response.data;
          }
        )
    },
    openDialog(id) {
      if (id) { this.investmentId = id }
      this.dialogOpen = true
    },
    closeDialog(changes) {
      if (changes !== null) { this.get() }
      this.investmentId = null
      this.dialogOpen = false
    }
  },
  mounted() {
    if (this.id) {
      this.get()
    }
  }
}
</script>
