
import axios from 'axios';
import { menuCatalog, userMenu, adminMenu } from '@/config/menu';
export default {
  namespaced: true,
  state: {
    menu: null
  },
  getters: {
    getMenu: state => state.menu
  },
  mutations: {
    setMenu (state, data) {
      state.menu = data
   }
  },
  actions: {
    // --- Inicializa Google Accounts
    menu ({commit, state}, email) {
      return axios.get(`${process.env.VUE_APP_API}/users?email=${email}`).then(
        response => {
          if (response.data.length) {
            const user = response.data[0];
            if (user.role === 'admin') {
              const menu = menuCatalog.filter(({title}) => adminMenu.includes(title))
              commit('setMenu', menu)
            } else {
              const menu = menuCatalog.filter(({title}) => userMenu.includes(title))
              commit('setMenu', menu)
            }
          }
          return state.menu
        }
      )
    }
  }
}