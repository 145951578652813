import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import GeneralLayout from '@/layout/GeneralLayout.vue'
import LandingView from '@/views/General/LandingView'
import AboutView from '@/views/General/AboutView'

import LoginView from '@/views/LoginView.vue'
import LocationsView from '@/views/LocationsView.vue'

import ManageView from '@/views/Admin/ManageView.vue'
import ManageLocationView from '@/views/Admin/ManageLocationView.vue'
import ManageUserView from '@/views/Admin/ManageUserView.vue'
import StatisticsView from '@/views/Admin/StatisticsView.vue'

const beforeDashboard = async () => {
  if (!sessionStorage.getItem('gsi')) return '/login'

  let profile = store.getters['gsi/getProfile']
  if (!profile) {
    profile = await store.dispatch('gsi/unpack')
    if (!profile.email) return '/login'
  }

  let menu = store.getters['config/getMenu']
  if (!menu) {
    menu = await store.dispatch('config/menu', profile.email)
    if (!menu) return '/login';
  }
}

const routes = [
  {
    path: '/',
    name: 'General',
    component: GeneralLayout,
    children: [
      {
        path: '',
        name: 'Index',
        component: LandingView
      },
      {
        path: 'about',
        name: 'About',
        component: AboutView
      },
      {
        path: 'locations',
        name: 'GeneralLocations',
        component: LocationsView
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/layout/DashboardLayout.vue'),
    beforeEnter: beforeDashboard,
    children: [
      {
        path: 'locations',
        name: 'AccountLocations',
        component: LocationsView
      },
      {
        path: 'manage',
        name: 'ManageView',
        component: ManageView
      },
      {
        path: 'manage/locations/:id',
        name: 'ManageLocationView',
        component: ManageLocationView
      },
      {
        path: 'manage/users/:id',
        name: 'ManageUserView',
        component: ManageUserView
      },
      {
        path: 'statistics',
        name: 'StatisticsView',
        component: StatisticsView
      },
      {
        path: '',
        name: 'AccountVoidRedirect',
        redirect: { name: 'AccountLocations' }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
