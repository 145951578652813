<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="1200px"
  >
    <v-card v-if="locations">
      <v-card-title class="pa-4">
        <span>RENTAL</span>
      </v-card-title>
      <v-card-text>
        <v-form
          id="data"
          ref="form"
          @submit.prevent="save(data)"
        >
          <v-row>
            <v-col class="py-0" cols="12" md="12" sm="12">
              <v-select
                v-model="data.locationId"
                :items="locations"
                item-title="title"
                item-value="id"
                label="Building"
                @update:modelValue="selectLocation($event)"
              />
            </v-col>
            <v-col class="py-0" cols="4" md="4" sm="12">
              <v-text-field
                name="date"
                label="Date"
                v-model="data.date"
                type="date"
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="4" md="4" sm="12">
              <v-select
                name="room"
                label="Room"
                :items="rooms"
                v-model="data.room"
                solo
              >
              </v-select>
            </v-col>
            <v-col class="py-0" cols="4" md="4" sm="12">
              <v-text-field
                name="amount"
                label="Amount"
                v-model="data.amount"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-text-field
                name="company"
                label="Company"
                v-model="data.company"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          type="submit"
          form="data"
        >
          Save
        </v-btn>
        <v-btn
          v-if="id"
          text
          @click="del()"
        >
          Delete
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="this.$emit('close', null)"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { rental } from '@/config/preset'
export default {
  props: {
    open: Boolean,
    id: Number,
    locationId: Number
  },
  data: () => ({
    data: structuredClone(rental),
    rooms: [],
    locations: null
  }),
  watch: {
    open: function (state) {
      if (state && this.id) {
        this.get();
      }
      if (state == false) {
        this.data = structuredClone(rental)
      }
    },
    locationId: function (state) {
      if (state) {
        this.data.locationId = state;
        this.selectLocation(state)
      }
    }
  },
  computed: {
    show () {
      return this.open
    }
  },
  methods: {
    get () {
      axios.get(`${process.env.VUE_APP_API}/rentals/${this.id}`)
        .then(
          response => {
            this.data = response.data;
          }
        )
    },
    del () {
      axios.delete(`${process.env.VUE_APP_API}/rentals/${this.id}`)
        .then(
          () => {
            this.$emit('close', false)
          }
        )
    },
    save (data) {
      if ('id' in data) {
        axios.put(`${process.env.VUE_APP_API}/rentals/${data.id}`, data)
          .then(
            response => {
              this.$emit('close', response.data)
            }
          )
      } else {
        axios.post(`${process.env.VUE_APP_API}/rentals`, data)
          .then(
            response => {
              this.$emit('close', response.data)
            }
          )
      }
    },
    getLocations () {
      axios.get(`${process.env.VUE_APP_API}/locations`)
        .then(
          response => {
            this.locations = response.data;
            if (this.locationId) {
              this.data.locationId = this.locationId;
              this.selectLocation(this.locationId);
            }
          }
        )
    },
    selectLocation (id) {
      const idx = this.locations.findIndex(location => id == location.id)
      if (idx != -1) this.rooms = this.locations[idx].rooms
    }
  },
  mounted () {
    this.getLocations()
    if (this.id) {
      this.get();
    }
  }
}
</script>