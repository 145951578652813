const offsetCardPx = 16
let activeCardIdx = 0
let cardsWidth = "90%"
let backgroundElement;

function createIlusionCard(pageWidth) {
    let div = document.createElement("div");
    div.classList.add("backlayer")
    div.style.width = (pageWidth > 1340) ? "1340px" : cardsWidth;
    return div
}

function calcule_visibility(stacked_cards, pageScrollTop, visibleOn) {
    let opacity = (pageScrollTop > visibleOn) ? 1 : 0;
    Array.from(stacked_cards).forEach((stacked_card) => {
        let backlayer = stacked_card.querySelector(".backlayer");
        backlayer.style.opacity = opacity;
    });
}

function calcule_on_screen(sections, pageScrollTop) {
    Array.from(sections).forEach((section) => {
        let elementTop = section.offsetTop - 100 - 100;
        if (pageScrollTop >= elementTop) {
            if (!section.classList.contains("passed")) section.classList.add("passed")
        } else section.classList.remove("passed")
    });
}

function calcule_offset(stacked_cards, pageScrollTop, pageScrollBottom) {
    let passedCards = -1
    Array.from(stacked_cards).forEach((stacked_card) => {
        let elementTop = stacked_card.offsetTop - 100 - 100;
        let elementBottom = elementTop + stacked_card.offsetHeight;
        if (pageScrollTop >= elementTop) passedCards++;
        if (pageScrollBottom >= elementBottom) {
            if (!stacked_card.classList.contains("hidden")) stacked_card.classList.add("hidden")
        } else stacked_card.classList.remove("hidden")
    });
    if (passedCards != activeCardIdx) {
        activeCardIdx = passedCards;
        let activeCard = stacked_cards[activeCardIdx]
        if (activeCard) {
            let color = activeCard.getAttribute("color");
            if (color) backgroundElement.style.backgroundColor = `${color}FF`
            else backgroundElement.style.backgroundColor = ""
        }
    }
}

export function run () {
        
    backgroundElement = document.getElementById("content");
    backgroundElement.style.transition = "background-color 1000ms"

    let pageHeight = window.innerHeight;
    let pageWidth = window.innerWidth;

    let visibleOn = pageHeight;

    let ilusionCard = createIlusionCard(pageWidth);    
    let sections = document.getElementsByTagName("section");

    Array.from(sections).forEach((section) => {
        let color = section.getAttribute("color");
        if (color) {
            let mainlayer = section.querySelector("article");
            mainlayer.style.backgroundColor = color;
        }
    });
    
    let stacked_cards = document.getElementsByClassName("stacked-card")
    Array.from(stacked_cards).forEach((stacked_card, idx) => {
        
        let backlayer = ilusionCard.cloneNode(false);
        let mainlayer = stacked_card.querySelector("article");
        
        let cardSequence = stacked_cards.length - idx;
        let color = stacked_card.getAttribute("color");

        if (pageWidth > 600) {
            backlayer.style.backgroundColor = color;
            backlayer.style.top = `${window.innerHeight - (offsetCardPx * cardSequence)}px`;
            backlayer.style.zIndex = idx + 9;
            backlayer.style.opacity = 0;
            backlayer.style.transition = "opacity 1000ms linear";
            mainlayer.style.zIndex = idx + 10;
            stacked_card.appendChild(backlayer);
        }
        
    });

    window.addEventListener("scroll", function() {
        let pageScrollTop = window.scrollY;
        let pageScrollBottom = pageHeight + pageScrollTop;
        calcule_on_screen(sections, pageScrollTop);
        calcule_offset(stacked_cards, pageScrollTop, pageScrollBottom);
        if (pageWidth > 600) {
            calcule_visibility(stacked_cards, pageScrollTop, visibleOn);
        }
    });

}