import { createStore } from 'vuex'
import gsiModule from './gsi'
import configModule from './config'

const state = {}
const actions = ({})
const mutations = ({})
const getters = ({})

const modules = {
  gsi: gsiModule,
  config: configModule
}

export default createStore({
  state,
  actions,
  mutations,
  getters,
  modules
});
