<template>
  <google-map-loader
    :markers="markers"
    @selected="selectLocation"
  />
  <location-detail-dialog
    :open="locationDetailDialog"
    :building="selectedBuilding"
    @close="locationDetailDialog=false"
  />
</template>

<script>
import axios from 'axios';
import GoogleMapLoader from '@/components/GoogleMapLoader.vue';
import LocationDetailDialog from '@/components/dialogs/LocationDetailDialog.vue';
export default {
  components: {
    GoogleMapLoader,
    LocationDetailDialog
  },
  data: () => ({
    locations: [],
    markers: [],
    selectedBuilding: null,
    locationDetailDialog: false
  }),
  methods: {
    retrieveLocations() {
      axios.get(`${process.env.VUE_APP_API}/locations`).then(
        response => {
          this.locations = response.data;
          this.markers = response.data.map((location) => (
            {
              id: location.id,
              title: location.title,
              position: {
                lat: location.location_lat,
                lng: location.location_lng
              }
            }
          ));
        }
      )
    },
    selectLocation(locationId) {
      const buildingIdx = this.locations.findIndex((location) => location.id === locationId)
      if (buildingIdx !== -1) {
        this.selectedBuilding = this.locations[buildingIdx]
        this.locationDetailDialog = true
      }
    }
  },
  mounted() {
    this.retrieveLocations()
  }
}
</script>