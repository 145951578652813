let parallaxElement;

function calculePositionY(pageScrollTop) {
    parallaxElement.style.marginTop = `${pageScrollTop/4}px`
}

export function run () {

    parallaxElement = document.getElementById('parallax');
    calculePositionY(0);

    window.addEventListener('scroll', function() {
        let pageScrollTop = window.scrollY;
        if (parallaxElement) {
            calculePositionY(pageScrollTop);
        }
    });
}